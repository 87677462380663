
import { UxModal, UxText, UxBtnGroup, UxBtn } from '@libui/ux-default-lib'
import { defineComponent } from 'vue'
UxBtn.define()
UxBtnGroup.define()
UxText.define()
UxModal.define()

export default defineComponent({
  props: {
    isIsinsModal: Boolean,
  },
  emits: ['actionModal'],
  data: function () {
    return {
      isIsins: false,
    }
  },
  methods: {
    onCancel() {
      this.$emit('actionModal', { action: 'close' })
    },
    onSubmit() {
      this.$emit('actionModal', { action: 'reset' })
    },
  },
})
