import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { "no-cross": "" }
const _hoisted_2 = {
  key: 0,
  tag: "p",
  bold: ""
}
const _hoisted_3 = {
  key: 1,
  tag: "p",
  bold: ""
}
const _hoisted_4 = { slot: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ux-modal", _hoisted_1, [
    (_ctx.isIsinsModal)
      ? (_openBlock(), _createElementBlock("ux-text", _hoisted_2, " Choisir un ou des ISIN(S) va effacer votre saisie de libellé, êtes-vous sûr de vouloir poursuivre ? "))
      : _createCommentVNode("", true),
    (!_ctx.isIsinsModal)
      ? (_openBlock(), _createElementBlock("ux-text", _hoisted_3, " Saisir un libellé va effacer votre saisie d'ISIN(S), êtes vous sûr de vouloir poursuivre ? "))
      : _createCommentVNode("", true),
    _createElementVNode("ux-btn-group", _hoisted_4, [
      _createElementVNode("ux-btn", {
        model: "secondary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSubmit()))
      }, "Continuer"),
      _createElementVNode("ux-btn", {
        model: "primary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCancel()))
      }, "Annuler")
    ])
  ]))
}