
import { UxImg } from '@libui/ux-default-lib'
UxImg.define()

import SearchComponent from './SearchComponent.vue'
import ResultsComponent from './ResultsComponent.vue'
import FooterComponent from './FooterComponent.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    SearchComponent,
    ResultsComponent,
    FooterComponent,
  },
})
