
import { defineComponent } from 'vue'
import {
  UxContainer,
  UxText,
  UxCardBody,
  UxAccordionItem,
  UxFlex,
  UxShortlink,
  UxSection,
  UxLinkBlank,
} from '@libui/ux-default-lib'
UxLinkBlank.define()
UxSection.define()
UxShortlink.define()
import { Underlying } from '@/types'

UxFlex.define()
UxAccordionItem.define()
UxCardBody.define()
UxText.define()
UxContainer.define()

import { store } from '../store/Store'
import { labelize } from '../services/TranscoService'

export default defineComponent({
  data() {
    return {
      store: store(),
    }
  },
  computed: {
    selectedProduct() {
      return store().product
    },
    selectedMandats(): Underlying[] {
      const { selectedDocuments } = store()
      return selectedDocuments.filter((data) => data.nature == 'MANDATS')
    },
    selectedGestion(): Underlying[] {
      const { selectedDocuments } = store()
      return selectedDocuments.filter((data) => data.nature == 'GESTION_PILOTEE')
    },
    selectedSupports(): Underlying[] {
      const { selectedDocuments } = store()
      return selectedDocuments
        .filter((data) => data.nature != 'MANDATS' && data.nature != 'GESTION_PILOTEE')
        .sort((a: Underlying, b: Underlying) => this.isinSort(a, b))
    },
    supports(): Underlying[] {
      const { documents } = store()
      return documents.filter((data) => data.nature != 'MANDATS' && data.nature != 'GESTION_PILOTEE')
    },
  },
  methods: {
    formatData(nature: string | null): string {
      if (nature) {
        return nature.replace(/_/g, ' ').toLowerCase()
      }
      return ''
    },
    formatLabel(codeLabel: string | null) {
      if (codeLabel !== null) {
        return labelize(codeLabel)
      }
    },
    formatClassification(codeClassification: number | null) {
      if (codeClassification === null || codeClassification === 0) {
        return 'N.C'
      }
      return codeClassification
    },
    isinSort(a: Underlying, b: Underlying) {
      const textA = a.isin.toUpperCase()
      const textB = b.isin.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    },
  },
})
