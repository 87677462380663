
import { defineComponent } from 'vue'
import MainPage from '@/components/MainPage.vue'
import { SofaEnvBanner, SofaAppVersion } from '@ccwm/sofa-lib-vue-components'

export default defineComponent({
  setup() {
    return {
      env: process.env.VUE_APP_ENV,
      version: process.env.VUE_APP_VERSION,
    }
  },
  components: {
    SofaEnvBanner,
    SofaAppVersion,
    MainPage,
  },
})
