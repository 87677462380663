import './addUxLibSVG'
import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/css/global.css'
import '@/assets/css/vue-multiselect.css'
import { createPinia } from 'pinia'
import { sendError } from '@sofa/lib-eum'

const app = createApp(App).use(createPinia())

app.config.errorHandler = function (err, vm, info) {
  // eslint-disable-next-line no-console
  if (process.env.VUE_APP_ENV !== 'PROD') console.error('Vue error', err, vm, info)
  // permet de remonter les erreurs à Instana
  sendError({ err, vm, info })
}
app.mount('#app')
