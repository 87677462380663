import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7125e40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchComponent = _resolveComponent("SearchComponent")!
  const _component_ResultsComponent = _resolveComponent("ResultsComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ux-img", {
      src: require('../assets/images/logo-surav.png'),
      alt: "logo de la marque",
      center: ""
    }, null, 8, _hoisted_2),
    _createElementVNode("section", null, [
      _createVNode(_component_SearchComponent),
      _createVNode(_component_ResultsComponent)
    ]),
    _createVNode(_component_FooterComponent)
  ]))
}