import { UxFooter, UxFooterItem, UxText } from '@libui/ux-default-lib'
<template>
  <div class="footer">
    <ux-footer>
      <ux-footer-item class="brand">© 2023 - SURAVENIR </ux-footer-item>
      <ux-footer-item class="item"
        ><a
          href="https://www.suravenir.fr/mentions-legales/"
          target="_blank"
          class="color-p-state"
          aria-label="Accéder à la page Mentions Légales"
          >MENTIONS LEGALES</a
        ></ux-footer-item
      >
      <ux-footer-item class="item"
        ><a
          href="https://www.suravenir.fr/information-reglementaire/"
          target="_blank"
          class="color-p-state"
          aria-label="Accéder à la page Informations Réglementaires"
          >INFORMATIONS REGLEMENTAIRES</a
        ></ux-footer-item
      >
    </ux-footer>
  </div>
</template>

<style scoped>
.footer {
  background-color: #333;
  color: white;
  height: 50px;
  line-height: 50px;
}

.brand {
  padding-left: 20px;
}
.item {
  padding-left: 10%;
}
</style>
