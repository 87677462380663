
import SearchModal from './SearchModal.vue'
import { defineComponent } from 'vue'
import { store } from '../store/Store'
import {
  UxBtn,
  UxCard,
  UxCardHeader,
  UxFlex,
  UxInputA11ySelect,
  UxText,
  UxCardBody,
  UxCardFooter,
  UxContainer,
  UxLoader,
} from '@libui/ux-default-lib'
import { Product } from '@/types'
import VueMultiselect from 'vue-multiselect'

UxLoader.define()
UxContainer.define()
UxCardBody.define()
UxCardHeader.define()
UxFlex.define()
UxInputA11ySelect.define()
UxBtn.define()
UxCard.define()
UxText.define()

UxCardHeader.define()
UxCardFooter.define()

export default defineComponent({
  components: {
    SearchModal,
    VueMultiselect,
  },
  computed: {
    selectedProduct() {
      return store().product
    },
    productDocuments() {
      return store().documents
    },
    products() {
      return store().products
    },
  },
  data() {
    return {
      productId: '',
      contractIsins: '',
      contractLib: '',
      showModal: false,
      isIsinsModal: false,
      store: store(),
      loading: false,
    }
  },
  methods: {
    test() {
      console.log(this.contractIsins)
    },
    getProductById(id: string) {
      return this.products.find((data: Product) => data.productId == id)
    },
    onIsinFilterChange() {
      this.showModal = this.contractLib !== ''
      this.isIsinsModal = true
    },
    onLabelFilterChange() {
      this.showModal = Object.keys(this.contractIsins).length > 0
      this.isIsinsModal = false
    },
    search() {
      this.loading = true
      const selectedProduct = this.getProductById(this.productId)
      if (!selectedProduct) {
        this.loading = false
        return
      }
      setTimeout(() => {
        this.store.searchProduct(this.contractIsins, this.contractLib).then(() => {
          this.store.showResult()
          this.loading = false
        })
      }, 250)
    },
    getProductLibelles() {
      return this.store.getProductLibelles()
    },
    getProductIsins() {
      return this.store.getProductIsins()
    },
    resetSearch() {
      this.store.hideResult()
      this.contractIsins = ''
      this.contractLib = ''
    },
    onClose(event: any) {
      this.showModal = false
    },
    onActionModal(event: any) {
      if (event.action === 'close') {
        if (this.isIsinsModal) {
          this.contractIsins = ''
        } else {
          this.contractLib = ''
        }
      } else {
        if (this.isIsinsModal) {
          this.contractLib = ''
        } else {
          this.contractIsins = ''
        }
        this.store.hideResult()
      }
      this.showModal = false
    },
  },
  watch: {
    productId: function (id) {
      this.resetSearch()
      this.loading = true
      const product = this.getProductById(id)
      if (!product) {
        this.loading = false
        // message d'erreur
        return
      }
      setTimeout(() => {
        this.store.selectProduct(product).then(() => {
          this.loading = false
        })
      }, 250)
    },
  },
  created() {
    this.store.fetchProducts()
    this.products = this.store.products
  },
})
