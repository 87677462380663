// eslint-disable-next-line
// @ts-nocheck

// !! Ne pas supprimer ce fichier !!
// Permet de récupérer les icônes pour les besoins de la lib ux
import iconsSvgUrl from '@libui/ux-default-lib/dist/assets/images/icons.svg'

if (!window.uxLib) {
  window.uxLib = {}
}
window.uxLib.iconsSvgUrl = window.location.origin + iconsSvgUrl
