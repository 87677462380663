import { defineStore } from 'pinia'
import { Product, State } from '@/types'
import axios from 'axios'
import { Underlying } from '@/types'

// the first argument is a unique id of the store across your application
export const store = defineStore('Store', {
  state(): State {
    return {
      products: [],
      documents: [],
      selectedDocuments: [],
      isDisplayResult: false,
    }
  },
  actions: {
    async fetchProducts() {
      try {
        const response = await axios.get(this.getS3JsonUrl() + 'products.json')
        this.products = response.data.sort((a: Product, b: Product) => a.productName.localeCompare(b.productName))
      } catch (error) {
        alert(error)
        console.log(error)
      }
    },
    async fetchUnderlyings(product: Product) {
      try {
        const response = await axios.get(this.getS3JsonUrl() + product.productId + '.json')
        this.documents = response.data.filter(this.getActiveStatus)
      } catch (error) {
        alert(product.productId + '.json not found')
        console.log(error)
      }
    },
    async selectProduct(product: Product) {
      this.product = product
      this.fetchUnderlyings(product)
    },
    async searchProduct(isins: any, libelle: any) {
      if (Object.keys(isins).length > 0) {
        this.selectedDocuments = this.documents.filter((data) => isins.find((el: string) => el == data.isin))
      } else if (libelle) {
        this.selectedDocuments = this.documents.filter((data) => data.name === libelle)
      } else {
        this.selectedDocuments = this.documents
      }
    },
    getProductIsins() {
      return [...this.documents].sort((a: Underlying, b: Underlying) => this.isinSort(a, b)).map((el) => el.isin)
    },
    getProductLibelles() {
      return [...this.documents].sort((a: Underlying, b: Underlying) => this.nameSort(a, b)).map((el) => el.name)
    },
    showResult() {
      this.isDisplayResult = true
    },
    hideResult() {
      this.isDisplayResult = false
    },
    getS3JsonUrl(): string {
      return (
        (process.env.VUE_APP_S3_SERVER_URL != undefined ? process.env.VUE_APP_S3_SERVER_URL : '') +
        process.env.VUE_APP_S3_JSON_PATH
      )
    },
    isinSort(a: Underlying, b: Underlying) {
      const textA = a.isin.toUpperCase()
      const textB = b.isin.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    },
    nameSort(a: Underlying, b: Underlying) {
      const textA = a.name.toUpperCase()
      const textB = b.name.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    },
    getActiveStatus(element: Product | Underlying) {
      return element.status === 'ACTIVE'
    },
  },
})
