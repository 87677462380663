enum LabelSupport {
  A = 'ICMA Green Bond Principles',
  B = 'EU Green Bond Standard',
  C = 'EU Ecolabel for Financial Products',
  D = 'FNG Siegel (Fonds)',
  E = 'CBI Climate Bonds Standards',
  F = 'ICMA Social Bonds Principles',
  G = 'LuxFLAG ESG',
  H = 'LuxFLAG Climate Finance',
  I = 'LuxFLAG Environment',
  J = 'Kein Verstoß gegen Atomwaffensperrvertrag',
  K = 'ISR',
  L = 'Febelfin / Towards Sustainability',
  M = 'UZ49 - das österreichische Umweltzeichen',
  N = 'Nordic Swan',
  O = 'GreenFin Label',
  P = 'Finansol',
  Q = 'DDV-Nachhaltigkeitskodex-ESG-Strategie',
  R = 'DDV-Nachhaltigkeitskodex-ESG-Impact',
  S = 'Grüner Pfandbrief',
  T = 'Sozialer Pfandbrief',
  U = 'LuxFLAG Microfinance',
  V = 'LuxFLAG Sustainable Insurance Products',
  Z = 'Other label',
}

function labelize2(key: keyof typeof LabelSupport): LabelSupport {
  return LabelSupport[key]
}

export function labelize(letters: any): string {
  let result = ''
  if (letters != null) {
    const listCodes: string[] = letters.split(';')
    if (listCodes != null && listCodes.length > 1) {
      listCodes.forEach((element) => (result = getLabel(element) + ', ' + result))
      result = result.substring(0, result.length - 2)
    }
  }
  return result
}

function getLabel(letter: string): string {
  switch (letter) {
    case 'A':
      return 'ICMA Green Bond Principles'
    case 'B':
      return 'EU Green Bond Standard'
    case 'C':
      return 'EU Ecolabel for Financial Products'
    case 'D':
      return 'FNG Siegel (Fonds)'
    case 'E':
      return 'CBI Climate Bonds Standards'
    case 'F':
      return 'ICMA Social Bonds Principles'
    case 'G':
      return 'LuxFLAG ESG'
    case 'H':
      return 'LuxFLAG Climate Finance'
    case 'I':
      return 'LuxFLAG Environment'
    case 'J':
      return 'Kein Verstoß gegen Atomwaffensperrvertrag'
    case 'K':
      return 'ISR'
    case 'L':
      return 'Febelfin / Towards Sustainability'
    case 'M':
      return 'UZ49 - das österreichische Umweltzeichen'
    case 'N':
      return 'Nordic Swan'
    case 'O':
      return 'GreenFin Label'
    case 'P':
      return 'Finansol'
    case 'Q':
      return 'DDV-Nachhaltigkeitskodex-ESG-Strategie'
    case 'R':
      return 'DDV-Nachhaltigkeitskodex-ESG-Impact'
    case 'S':
      return 'Grüner Pfandbrief'
    case 'T':
      return 'Sozialer Pfandbrief'
    case 'U':
      return 'LuxFLAG Microfinance'
    case 'V':
      return 'LuxFLAG Sustainable Insurance Products'
    default:
      return 'Other label'
  }
}
