import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SofaEnvBanner = _resolveComponent("SofaEnvBanner")!
  const _component_MainPage = _resolveComponent("MainPage")!
  const _component_SofaAppVersion = _resolveComponent("SofaAppVersion")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SofaEnvBanner, {
      env: _ctx.env,
      position: "top-right"
    }, null, 8, ["env"]),
    _createVNode(_component_MainPage),
    _createVNode(_component_SofaAppVersion, { version: _ctx.version }, null, 8, ["version"])
  ]))
}